@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --primary-dark-color: #0e0d0d;
  --second-dark-color: #4a4545;
}
body {
  margin: 0;
  background-color: #f5f6f8;
}

* {
  font-family: "Poppins", sans-serif !important;
}

.form-div {
  width: 50%;
  display: flex;
}

.segmentation-table-div {
  margin-top: 31px;
  text-align: -webkit-center;
}

.common-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;
}

.delete-btn {
  margin-left: 4px;
}

.dashboard-title {
  text-align: left;
  color: #000;
  font-size: x-large;
}

.dashboard-title-main {
  padding: 22px 24px;
  background-color: #fff;
  display: inline-block;
  width: 100%;
}

.dashboard-title-main h1 {
  margin: 0px;
}

.main-div-sidebar-menu {
  background: #fff;
}

.header-title-div {
  text-align: center;
}

.data-header {
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e9;
}

.data-header .heading {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: rgb(14, 13, 13);
  margin: 0px;
}

.segmentation-table-div {
  padding: 20px;
}

.dashboard-title-h1 {
  text-align: center;
}

.auth-sidebar {
  max-width: 200px;
  min-width: 240px !important;
  width: 250px;
}

.add-edit-btn {
  text-align: left;
  margin-bottom: 11px;
}

.common-search-div {
  margin-bottom: 15px;
}

.common-pagination-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
  margin-bottom: 15px;
}

.ant-form-cs .ant-form-item-label {
  width: 20%;
  text-align: left;
}

.ant-form-cs .ant-form-item-control {
  width: 80%;
}

.p-4 {
  padding: 16px;
}

.login-title-span {
  padding-left: 5px;
  color: #1677ff;
  font-size: 22px;
  font-weight: bold;
}

.login-title-p {
  padding-left: 44%;
  margin-top: 8%;
  margin-bottom: 30px;
}

.registration-title-p {
  padding-left: 44%;
  margin-top: 8%;
  margin-bottom: 30px;
}

.login-btn {
  width: 100%;
  margin-bottom: 10px;
}

.login-card-div {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ant-form-login .ant-form-item-label {
  width: 25%;
  text-align: left;
}

.ant-form-login .ant-form-item-control {
  width: 80%;
}

.image-upload {
  margin-bottom: 70px;
}

.common-image-upload {
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
}

.common-image-upload {
  margin-bottom: 24px;
}

.custom-dragger .ant-upload-list-item-action-remove {
  display: none;
}

.login-form-button {
  width: 100%;
}

.login-form button[type="submit"] {
  margin-bottom: 20px;
}

.space40 {
  height: 30px;
  width: 100%;
  display: block;
}

.centerAbsolute {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 38px;
  margin-left: -16px;
  margin-top: -19px;
}

.shadow {
  box-shadow: 0px 0px 20px 3px rgba(150, 190, 238, 0.15);
}

.whiteBox {
  background: #fff;
  border-radius: 6px;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.profileDropdown .pad15 {
  display: flex;
}

.profileDropdown .info {
  float: left;
  display: inline;
  padding-left: 15px;
}

.profileDropdown .info p.strong {
  margin: 0;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 600;
  color: var(--primary-dark-color);
}
.profileDropdown .info p {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  color: var(--second-dark-color);
}

.pad15 {
  padding: 20px;
}

.headerIcon {
  display: flex;
  justify-content: flex-end;
  background: #fcfcfc;
  padding: 20px 30px;
  box-shadow: 0 0 40px 0px rgb(0 0 0 / 8%);
  z-index: 999;
}

.headerIcon .ant-avatar {
  background: #0d4bc1;
  cursor: pointer;
}

.headerIcon .ant-avatar:hover {
  background: #1964f0;
}

.sidePanelContent {
  transition: all 0.3s ease-in-out;
  margin-top: 0;
}

.sidePanel {
  border-right: 1px solid #edf0f5;
  background: #fcfcfc !important;
  box-shadow: 0 0 20px 0px rgb(0 0 0 / 8%);
}

.sidePanelContent {
  transition: all 0.3s ease-in-out;
  margin-top: 0;
}

.sideMenu {
  border: 0 !important;
  background: #fcfcfc;
}

.sideMenu li,
.sideMenu .ant-menu-submenu .ant-menu-submenu-title {
  height: unset !important;
  padding: 14px 10px;
  margin: 0px !important;
  width: 100% !important;
  margin: 0px;
  border-radius: 0px !important;
}

.sideMenu .ant-menu-submenu {
  padding: 0;
}

.sideMenu .ant-menu-submenu .ant-menu-sub {
  background: #fcfcfc !important;
}

.sideMenu .ant-menu-submenu .ant-menu-sub li {
  padding-left: 35px !important;
}

.sideMenu li .ant-menu-item-icon {
  width: 40px;
  height: 40px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 1.3em;
  line-height: 40px !important;
  border-radius: 8px;
  box-shadow: 0 3px 12px #0000001f;
}

.sideMenu li .ant-menu-item-icon svg {
  font-size: 20px;
}

.sideMenu li .ant-menu-title-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 25px !important;
  /* color: #1d1e1e; */
}

.main-content {
  background: #f1f3f6;
  padding: 40px 50px;
  border-radius: 0px;
  margin: "100px auto";
  flex: "none";
}

.main-content-warp {
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
}

.breadcrumb {
  margin-bottom: 30px;
}

.breadcrumb .data-header {
  padding-bottom: 0px;
  border: unset;
}

.breadcrumb ol li:last-child .ant-breadcrumb-link a {
  color: #000;
  font-weight: 600;
}

.add__btn {
  display: flex;
  justify-content: flex-end;
}

.global__table {
  padding-top: 30px;
}

.global__form {
  padding-top: 30px;
}
.ant-menu-vertical .ant-menu-title-content {
  transition: all 0.5s ease-in-out !important;
  display: none !important;
}
.sideMenu.ant-menu-vertical li {
  padding: 18px 22px !important;
}
.sideMenu.ant-menu-vertical li:last-child {
  padding: 0px !important;
}
.sideMenu.ant-menu-vertical li:last-child .ant-menu-submenu-title {
  padding: 18px 22px;
}
.ant-dropdown-menu {
  box-shadow: none !important;
  background-color: #ffffff !important;
  border-radius: 0px !important;
  border-top: 1px solid #ddd;
}

.logout__btn li .anticon {
  margin-right: 15px;
}

.text-center {
  display: flex;
  justify-content: center;
}
